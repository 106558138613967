import React from 'react'
import { x } from '@xstyled/styled-components'
import { Popup, usePopupState } from './ui/Popup'
import { useCookie, isBrowser } from './ui/utils'


const CookieForm = (props) => {
  const popup = usePopupState({visible: true})
  const [consent, setConsent] = useCookie('Cookie-Consent', 'none')
  if (isBrowser && consent === 'none') {
    return (
      <Popup {...popup} placement='right' size='sm'>
        <x.div display="flex" flexDirection='row' alignItems='center' justifyContent='center' backgroundColor='ltGrey' borderRadius={3} p={10} gap={10}>
          <x.p text={{ sm: 'xsmall', lg: 'medium' }}>This website uses cookies to ensure you get the best experience on our website. <x.a href='/privacy-policy' color={{ _: 'blue', hover: '' }}>Learn more</x.a></x.p>
          <x.button text={{ sm: 'xsmall', lg: 'medium' }} textTransform='uppercase' color='white' bg={{ _: 'blue', hover: 'ltBlue' }} p={{ sm: '3px 10px', lg: '8px 10px' }} lineHeight='1' borderRadius={3} alignSelf='flex-end' onClick={()=>setConsent('accepted', {days: 365})}>Accept</x.button>
        </x.div>
      </Popup>
    )
  } else {
    return ''
  }
}

export default CookieForm