/* eslint-disable react/no-multi-comp */
import React from 'react'
import { func, node, oneOf } from 'prop-types'
import { Dialog, useDialogState } from 'reakit/Dialog'
import ClearButton from '../../../../assets/images/icons/close.svg'

import * as S from './Popup.style'

export function Popup({ children, placement = 'right', size = 'lg', ...rest }) {
  return (
    // Needed to allow to style the backdrop
    // see: https://reakit.io/docs/styling/#css-in-js
    <Dialog hideOnClickOutside={false} {...rest}>
      {props => (
        <S.Popup {...props} placement={placement} size={size}>
          <PopupClose {...rest} />
          {children}
        </S.Popup>
      )}
    </Dialog>
  )
}

Popup.propTypes /* remove-proptypes */ = {
  children: node.isRequired,
  placement: oneOf(['top', 'right', 'bottom', 'left']).isRequired,
  size: oneOf(['sm', 'md', 'lg', 'auto'])
}

export function usePopupState(options) {
  return useDialogState({ modal: false, ...options })
}


export function PopupClose({ hide, ...rest }) {
  return <S.Close onClick={hide} {...rest}><img src={ClearButton} width={24} height={24}/></S.Close>
}

PopupClose.propTypes = {
  hide: func.isRequired
}

Popup.Close = PopupClose
