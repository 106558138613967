import styled, { css, th, breakpoints } from '@xstyled/styled-components'

const getPlacementStyle = placement => {
  switch (placement) {
    case 'top':
      return {
        top: '20px',
        right: '20px'
      }
    case 'right':
      return css`
        ${breakpoints({
            xs: {
              /* All devices */
              right: '10px',
              left: '10px',
              bottom: '146px'
            },
            md: {
              /* From md breakpoint */
              right: '10px',
              left: '10px',
              bottom: '146px'
            },
            lg: {
              /* From lg breakpoint */
              right: '10px',
              left: 'auto',
              bottom: '10px'
            }
          })}`
    case 'bottom':
      return {
        bottom: '20px',
        left: '20px'
      }
    case 'left':
      return {
        top: '20px',
        left: '20px'
      }
  }
}

const SIZES = ['sm', 'md', 'lg']
const getSizeStyle = (size, placement) => {
  switch (placement) {
    case 'top':
    case 'bottom':
      if (SIZES.includes(size)) {
        return th(`popups.sizes.vertical.${size}`)
      }
      return {
        height: size
      }
    case 'right':
    case 'left':
      if (SIZES.includes(size)) {
        return th(`popups.sizes.horizontal.${size}`)
      }
      return {
        width: size
      }
  }
}

export const Popup = styled.div`
  ${({ placement, size }) => css`
    ${th('popups.default')};
    ${getPlacementStyle(placement)}
    ${getSizeStyle(size, placement)}
    position: fixed;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    transition: medium;
    cursor: auto;
    border-radius: 3px;
    &:focus {
      /* important for firefox */
      outline: none !important;
    }
    /* on open/close dialog for animation */
    &[data-enter] {
      transform: translate(0, 0);
    }
    &[data-leave] {
      transition: fast;
    }
  `
  }`

export const Close = styled.div`
  ${th('popups.closeButton')}
`
